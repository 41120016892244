
// import { Divider } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { GraduationCap, BookOpen, Users, Library, Star } from 'lucide-react';
const LMSPage = () => {
    const courses1 = [
        {
            id: 1,
            title: 'Master React.js',
            subtitle: 'Modern UI Development',
            description: 'Build and deploy fast, responsive web apps with React.',
            image: 'react-course.jpg',
            level: 'Intermediate',
            duration: '12 weeks'
        },
        {
            id: 2,
            title: 'AI Essentials',
            subtitle: 'Beginner’s Guide to AI',
            description: 'Discover the basics of AI and machine learning models.',
            image: 'ai-course.jpg',
            level: 'Beginner',
            duration: '10 weeks'
        },
        {
            id: 3,
            title: 'Full-Stack with Node.js',
            subtitle: 'Backend Development',
            description: 'Learn how to build full-stack applications with Node.js.',
            image: 'nodejs-course.jpg',
            level: 'Advanced',
            duration: '16 weeks'
        }
    ];
    const categories = [
        { name: 'Ongoing (08)', active: true },
        { name: 'Completed (10)', active: false },
        { name: 'Saved (12)', active: false },
        { name: 'Favorite (25)', active: false },
    ];

    const courses = [
        {
            title: ' Web Development',
            category: 'Development',
            instructor: ' James',
            rating: 4.9,
            reviews: '12k',
            image: 'https://images.pexels.com/photos/3471423/pexels-photo-3471423.jpeg?auto=compress&cs=tinysrgb&w=400',
            categoryColor: 'bg-green-100 text-green-600',
            avatar: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=400',
            completed: 45,
            daysLeft: 10,
            duration: 30,
        },
        {
            title: 'UI/UX Design Course',
            category: 'Design',
            completed: 15,
            daysLeft: 5,
            duration: 30,
            instructor: 'deepak',
            rating: 4.9,
            reviews: '12k',
            avatar: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/7109162/pexels-photo-7109162.jpeg?auto=compress&cs=tinysrgb&w=400',
            categoryColor: 'bg-orange-100 text-orange-600'
        },
        {
            title: 'React Native Course',
            category: 'Frontend',
            completed: 25,
            daysLeft: 2,
            duration: 30,
            instructor: 'disha  ',
            rating: 4.9,
            reviews: '12k',
            avatar: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/210661/pexels-photo-210661.jpeg?auto=compress&cs=tinysrgb&w=400',
            categoryColor: 'bg-red-100 text-red-600'
        },
        {
            title: 'SEO Expert A To Z ',
            category: 'Marketing',
            completed: 40,
            daysLeft: 16,
            duration: 30,
            instructor: 'Albert ',
            rating: 4.9,
            reviews: '12k',
            avatar: 'https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=400',
            image: 'https://images.pexels.com/photos/3949100/pexels-photo-3949100.jpeg?auto=compress&cs=tinysrgb&w=600',
            categoryColor: 'bg-cyan-100 text-cyan-600'
        }
    ];

    const stats = [
        {
            icon: <GraduationCap size={24} className="text-purple-600" />,
            value: "155+",
            label: "Completed Courses",
            trend: true
        },
        {
            icon: <BookOpen size={24} className="text-emerald-500" />,
            value: "39+",
            label: "Earned Certificates",
            trend: true
        },
        {
            icon: <Library size={24} className="text-purple-600" />,
            value: "25+",
            label: "Course in Progress",
            trend: true
        },
        {
            icon: <Users size={24} className="text-orange-400" />,
            value: "18k+",
            label: "Community Support",
            trend: true
        }
    ];
    const modules = [
        {
            id: 1,
            title: 'Backend Capstone Project',
            status: 'Completed',
            mockInterview: 'Not Started'
        },
        {
            id: 2,
            title: 'Data Engineering',
            status: 'Completed',
            mockInterview: 'Not Required'
        },
        {
            id: 3,
            title: 'DSA for Competitive Programming',
            status: 'Completed',
            mockInterview: 'Not Required'
        },
        {
            id: 4,
            title: 'Product Management for Software Engineers',
            status: 'In Progress',
            mockInterview: 'Not Required',
            days: [
                { number: '1', date: '28 AUG', title: 'Introduction to Product Management 1', lecture: '58.4%', assignment: '0/5' },
                { number: '2', date: '30 AUG', title: 'Understanding the Product Lifecycle 1', lecture: '0.0%', assignment: '0/5' },
                { number: '3', date: '2 SEP', title: 'Understanding the Product Lifecycle 2', lecture: '0.0%', assignment: '0/5' },
                { number: '4', date: '4 SEP', title: 'Critical Thinking in Product Development 1', lecture: '0.0%', assignment: '0/5' },
                { number: '5', date: '6 SEP', title: 'Critical Thinking in Product Development 2', lecture: '36.9%', assignment: '0/5' },
                { number: '6', date: '9 SEP', title: 'Market Structure Analysis and Its Technical Aspects 1', lecture: '0.0%', assignment: '0/5' },
                { number: '7', date: '4 SEP', title: 'Critical Thinking in Product Development 1', lecture: '0.0%', assignment: '0/5' },
                { number: '8', date: '6 SEP', title: 'Critical Thinking in Product Development 2', lecture: '36.9%', assignment: '0/5' },
                { number: '9', date: '9 SEP', title: 'Market Structure Analysis and Its Technical Aspects 1', lecture: '0.0%', assignment: '0/5' },
                { number: '10', date: '4 SEP', title: 'Critical Thinking in Product Development 1', lecture: '0.0%', assignment: '0/5' },
                { number: '11', date: '6 SEP', title: 'Critical Thinking in Product Development 2', lecture: '36.9%', assignment: '0/5' },
                { number: '12', date: '9 SEP', title: 'Market Structure Analysis and Its Technical Aspects 1', lecture: '0.0%', assignment: '0/5' },
                { number: '13', date: '4 SEP', title: 'Critical Thinking in Product Development 1', lecture: '0.0%', assignment: '0/5' },
                { number: '14', date: '6 SEP', title: 'Critical Thinking in Product Development 2', lecture: '36.9%', assignment: '0/5' },
                { number: '15', date: '9 SEP', title: 'Market Structure Analysis and Its Technical Aspects 1', lecture: '0.0%', assignment: '0/5' }
            ]
        },
        {
            id: 5,
            title: 'Machine Learning Basics',
            status: 'In Progress',
            mockInterview: 'Scheduled',
            days: [
                { number: '1', date: '10 SEP', title: 'Introduction to ML', lecture: '75.0%', assignment: '2/3' },
                { number: '2', date: '12 SEP', title: 'Supervised Learning', lecture: '0.0%', assignment: '0/4' },
                { number: '3', date: '14 SEP', title: 'Unsupervised Learning', lecture: '0.0%', assignment: '0/4' }
            ]
        },
        {
            id: 6,
            title: 'Frontend Development Basics',
            status: 'Not Started',
            mockInterview: 'Not Required'
        },
        {
            id: 7,
            title: 'Cloud Computing Essentials',
            status: 'Completed',
            mockInterview: 'Not Required'
        },
        {
            id: 8,
            title: 'Full Stack Development',
            status: 'In Progress',
            mockInterview: 'Scheduled',
            days: [
                { number: '1', date: '5 SEP', title: 'HTML & CSS Basics', lecture: '100%', assignment: '5/5' },
                { number: '2', date: '7 SEP', title: 'JavaScript Introduction', lecture: '50.0%', assignment: '1/3' },
                { number: '3', date: '9 SEP', title: 'Node.js Basics', lecture: '20.0%', assignment: '0/3' }
            ]
        },
        {
            id: 9,
            title: 'Data Science Foundations',
            status: 'In Progress',
            mockInterview: 'Scheduled',
            days: [
                { number: '1', date: '12 SEP', title: 'Data Cleaning Techniques', lecture: '40.0%', assignment: '1/4' },
                { number: '2', date: '14 SEP', title: 'Data Visualization', lecture: '10.0%', assignment: '0/3' },
                { number: '3', date: '16 SEP', title: 'Statistical Analysis', lecture: '0.0%', assignment: '0/3' }
            ]
        }
    ];

    return (
        <>
            <div className="mb-6">

                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>LMS-Dashbaord</span>
                </div>
            </div>
            <div className="h-auto bg-gray-100 dark:bg-slate-900 text-gray-800 dark:text-gray-100 p-8">
                <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                        {/* Welcome Section */}
                        <div className="lg:col-span-2">
                        <div className="p-6 bg-white dark:bg-gray-900 shadow-lg rounded-lg">
    <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
        <div className="space-y-4">
            <div className="space-y-2">
                <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-100 text-left">
                    Hi, Tanisha 👋
                </h1>
                <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 text-left">
                    What do you want to learn today with your partner?
                </h2>
            </div>
            <p className="text-gray-600 text-left">
                Discover courses, track progress, and achieve your learning goals seamlessly.
            </p>
            <div className="flex justify-start">
                <button className="bg-purple-600 hover:bg-purple-700 text-white rounded-md p-2">
                    Explore Courses
                </button>
            </div>
        </div>
        <div className="mt-4 lg:mt-0">
            <img
                src="/assets/images/pur.png"
                alt="Learning Illustration"
                className="w-full max-w-md"
            />
        </div>
    </div>
</div>

                        </div>


                        {/* Stats Grid */}
                        <div className="lg:col-span-1 ">
                            <div className="grid grid-cols-2 gap-4 bg-gray-100 dark:bg-slate-800 text-gray-800 dark:text-white">
                                {stats.map((stat, index) => (
                                    <div key={index} className="p-4 bg-white dark:bg-gray-900 shadow-lg rounded-lg">
                                    <div className="space-y-2">
                                        <div className="flex items-center justify-between">
                                            <div className="p-2 rounded-lg bg-gray-200">
                                                {stat.icon}
                                            </div>
                                
                                            <div>
                                                <h3 className="text-xl font-bold text-gray-800 dark:text-white mt-5">
                                                    {stat.value}
                                                </h3>
                                                <p className="text-sm text-gray-600 text-center">
                                                    {stat.label}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-6 bg-gray-100 dark:bg-slate-900 text-gray-800 dark:text-white ">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4 text-left">
                    Your Courses
                </h2>

                {/* Categories Filter */}
                <div className="flex flex-wrap gap-3 mb-6 text-gray-700 dark:text-white text-md">
                    {categories.map((category, index) => (
                        <button
                            key={index}
                            variant={category.active ? "default" : "outline"}
                            className={`rounded-lg py-1 px-3 ${category.active ? "border border-purple-500 dark:text-white text-gray-700" : "border-gray-300  dark:text-white text-gray-500"}`}
                        >
                            {category.name}
                        </button>
                    ))}
                </div>

                {/* Course Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {courses.map((course, index) => (
                     <div key={index} className="overflow-hidden rounded-lg shadow-sm bg-white dark:bg-slate-900 dark:text-white">
                     {/* Course Image */}
                     <div className="relative h-48">
                         <img
                             src={course.image}
                             alt={course.title}
                             className="w-full h-full object-cover rounded-t-lg"
                         />
                     </div>
                 
                     {/* Course Content */}
                     <div className="p-3 space-y-2 text-left">
                         {/* Category Tag */}
                         <span className={`inline-block px-2 py-1 rounded-full text-xs ${course.categoryColor}`}>
                             {course.category}
                         </span>
                 
                         {/* Course Title */}
                         <h3 className="font-semibold text-lg text-gray-800 dark:text-white">{course.title}</h3>
                 
                         {/* Progress Bar */}
                         <div className="space-y-1">
                             <div className="w-full bg-gray-200 rounded-full h-2">
                                 <div
                                     className="bg-purple-500 dark:bg-indigo-600 h-2 rounded-full"
                                     style={{ width: `${course.completed}%` }}
                                 ></div>
                             </div>
                             <div className="flex justify-between text-xs text-gray-500">
                                 <span>Completed: {course.completed}%</span>
                                 <span>{course.daysLeft} days left</span>
                             </div>
                         </div>
                 
                         {/* Instructor & Rating */}
                         <div className="flex items-center justify-between pt-2">
                             <div className="flex items-center space-x-2">
                                 <img
                                     src={course.avatar}
                                     alt={course.instructor}
                                     className="w-8 h-8 rounded-full border border-gray-300"
                                 />
                                 <div className="text-xs text-gray-600">
                                     <p>Created by</p>
                                     <p className="font-medium text-gray-800">{course.instructor}</p>
                                 </div>
                             </div>
                             <div className="flex items-center space-x-1">
                                 <Star className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                                 <span className="font-medium text-gray-800">{course.rating}</span>
                                 <span className="text-gray-500">({course.reviews})</span>
                             </div>
                         </div>
                 
                         {/* Continue Button */}
                         <button
                             onClick={() => window.location.href = '/videos'}
                             className="w-full bg-purple-500 hover:bg-purple-600 text-white rounded-lg py-2 mt-2"
                         >
                             Continue Watching
                         </button>
                     </div>
                 </div>
                 
                    ))}
                </div>
            </div>





        </>
    );
};

export default LMSPage;