import React, { useState } from 'react';
import { MapPin, Search, Clock, Heart, SlidersHorizontal, Save, Share2, SaveIcon, } from 'lucide-react';

import { FaSave } from 'react-icons/fa';

const JobDashboard = () => {
    const [job, setJobs] = useState([
        // Sample job data
        { id: 1, position: "Product Designer", company: "MetaMask", postedOn: "2024-10-15", logo: "https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png" },
        { id: 2, position: "Software Engineer", company: "Tech Corp", postedOn: "2024-10-10", logo: "https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png" },
        { id: 3, position: "Marketing Manager", company: "Marketing LLC", postedOn: "2024-10-20", logo: "https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png" },
    ]);

    const [sortOrder, setSortOrder] = useState('mostRecent');

    const handleSort = () => {
        const sortedJobs = [...job].sort((a, b) => new Date(b.postedOn) - new Date(a.postedOn));
        setJobs(sortedJobs);
        setSortOrder('mostRecent');
    };
    const jobs = [
        {
            company: "MetaMask",
            logo: "https://cdn.pixabay.com/photo/2021/12/14/22/29/meta-6871457_1280.png",
            position: "Product Designer",
            applicants: "25 Applicants",
            tags: ["Entry Level", "Full-Time"],
            description: "Doing the right thing for investors is what we're all about at Vanguard, and that includes...",
            salary: "$250/hr",
            postedTime: "Posted 2 days ago"


        },
        {
            company: "Netflix",
            logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXeMySNjwvvK61yM2AvfboJC6nZPSYebVjyw&s",
            position: "Sr. UX Designer",
            applicants: "14 Applicants",
            tags: ["Expert", "Part-Time", "Remote"],
            description: "Netflix is one of the world's leading streaming entertainment services...",
            salary: "$195/hr",
            postedTime: "Posted 1 days ago"


        },

        {
            company: "Google",
            logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaa2hwiG4o2vVy3yYZKTBDlqkTuQ0n3KEL2w&s",
            position: "Software Engineer",
            applicants: "30 Applicants",
            tags: ["Expert", "Full-Time"],
            description: "Join our innovative team at Google to work on exciting projects that impact millions...",
            salary: "$220/hr",
            postedTime: "Posted 3 days ago"


        },
        {
            company: "Apple",
            logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaURCDEX-d8qAHRsaLPq92BV0UFx8nDYnWgg&s",
            position: "UI/UX Designer",
            applicants: "22 Applicants",
            tags: ["Intermediate", "Full-Time", "Remote"],
            description: "Design intuitive and engaging user interfaces for Apple products and services...",
            salary: "$200/hr",
            postedTime: "Posted 4 days ago"


        },
        {
            company: "Amazon",
            logo: "https://cdn0.iconfinder.com/data/icons/most-usable-logos/120/Amazon-512.png",
            position: "Data Scientist",
            applicants: "15 Applicants",
            tags: ["Intermediate", "Full-Time"],
            description: "Analyze data and provide actionable insights to drive business decisions at Amazon...",
            salary: "$180/hr",
            postedTime: "Posted 8 days ago"


        },
        {
            company: "Tesla",
            logo: "https://w7.pngwing.com/pngs/628/405/png-transparent-tesla-logo-icon.png",
            position: "Mechanical Engineer",
            applicants: "12 Applicants",
            tags: ["Intermediate", "Full-Time"],
            description: "Work on cutting-edge electric vehicles and battery technology at Tesla...",
            salary: "$195/hr",
            postedTime: "Posted 9 days ago"


        },
        {
            company: "Adobe",
            logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ83crHmsZOWIxClObXAFYYr1CjJZBBk8e0hw&s",
            position: "Creative Cloud Engineer",
            applicants: "10 Applicants",
            tags: ["Expert", "Full-Time"],
            description: "Join the Adobe team to build innovative tools for creatives worldwide...",
            salary: "$210/hr",
            postedTime: "Posted 10 days ago"


        },
        {
            company: "Slack",
            logo: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/306_Slack_logo-512.png",
            position: "Product Manager",
            applicants: "8 Applicants",
            tags: ["Expert", "Full-Time"],
            description: "Lead the product strategy and execution for Slack's next-generation features...",
            salary: "$230/hr",
            postedTime: "Posted 12 days ago"


        },
        {
            company: "Microsoft",
            logo: "https://banner2.cleanpng.com/20171216/419/av2e7cawv.webp",
            position: "Product Designer",
            applicants: "18 Applicants",
            tags: ["Intermediate", "Full-Time"],
            description: "Welcome to Lightspeed LA, the first U.S.-based, AAA game development studio...",
            salary: "$210/hr",
            postedTime: "Posted 5 days ago"


        },

    ];


    return (
        <>
            <div className="mb-6">

                <div className="flex items-center text-sm text-gray-500">
                    <span className="hover:text-blue-600 cursor-pointer">Home</span>
                    <span className="mx-2">›</span>
                    <span className=' text-purple-600'>Job Dashboard</span>
                </div>
            </div>

            <div className="min-h-screen bg-white dark:bg-slate-950 text-slate-900 dark:text-white">
                {/* Hero Section */}
                <div className="max-w-5xl mx-auto text-center px-8 py-12 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-lg shadow-2xl relative">
                    <h3 className="text-4xl font-semibold text-white mb-8 tracking-wide">Discover Your Ideal Job ✨</h3>

                    <div className="flex bg-white dark:bg-slate-900 rounded-full shadow-md overflow-hidden ring-2 ring-gray-200 dark:ring-slate-700 transition-all">
                        {/* Job Title Input */}
                        <div className="flex items-center px-6 w-1/2 border-r border-gray-200 dark:border-slate-700">
                            <Search className="w-6 h-6 text-gray-400 dark:text-slate-500 mr-3" />
                            <input
                                type="text"
                                placeholder="Job title or keyword"
                                className="w-full bg-transparent placeholder-gray-500 dark:placeholder-slate-500 text-gray-700 dark:text-gray-300 focus:outline-none focus:ring-0 focus:text-indigo-600 dark:focus:text-indigo-300 transition"
                            />
                        </div>

                        {/* Location Input */}
                        <div className="flex items-center px-6 w-1/2 border-r border-gray-200 dark:border-slate-700">
                            <MapPin className="w-6 h-6 text-gray-400 dark:text-slate-500 mr-3" />
                            <input
                                type="text"
                                placeholder="Add country or city"
                                className="w-full bg-transparent placeholder-gray-500 dark:placeholder-slate-500 text-gray-700 dark:text-gray-300 focus:outline-none focus:ring-0 focus:text-indigo-600 dark:focus:text-indigo-300 transition"
                            />
                        </div>

                        {/* Search Button */}
                        <button className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-8 py-2 font-semibold rounded-full hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 transform hover:scale-105">
                            Search
                        </button>
                    </div>
                </div>


                {/* Main Content */}
                <div className="max-w-6xl mx-auto py-10 px-6">
                    <div className="flex justify-between items-center mb-8">
                        <h5 className="text-xl font-semibold">Recommended Jobs</h5>
                        <button
                            onClick={handleSort}
                            className="border border-indigo-600 text-indigo-600 px-4 py-1.5 rounded-lg flex items-center"
                        >
                            <SlidersHorizontal className="mr-2" /> Most Recent
                        </button>
                    </div>

                    <div className="flex flex-col md:flex-row gap-10">
                        {/* Filters */}
                        <div className="w-full md:w-1/4 space-y-6">
                            {/* Job Type Filter */}
                            <div className="bg-gray-100 dark:bg-slate-900 rounded-lg p-4  ">
                                <div className="flex justify-between items-center mb-2">
                                    <span className="font-semibold">Job Type</span>
                                    <button className="text-indigo-600 text-sm">Clear All</button>
                                </div>
                                {['Full Time', 'Part Time', 'Internship', 'Project Work', 'Volunteering'].map((type) => (
                                    <label key={type} className="flex items-center mb-2 text-sm cursor-pointer">
                                        <input type="checkbox" className="mr-2" />
                                        <span>{type}</span>
                                    </label>
                                ))}
                            </div>

                            {/* Job Category Filter */}
                            <div className="bg-gray-100 dark:bg-slate-900 rounded-lg p-4 ">
                                <span className="font-semibold">Job Category</span>
                                {['IT', 'Finance', 'Marketing', 'Healthcare', 'Education'].map((category) => (
                                    <label key={category} className="flex items-center mb-2 text-sm cursor-pointer">
                                        <input type="checkbox" className="mr-2" />
                                        <span>{category}</span>
                                    </label>
                                ))}
                            </div>

                            {/* Salary Range Filter */}
                            <div className="bg-gray-100 dark:bg-slate-900 rounded-lg p-4 ">
                                <span className="font-semibold">Salary Range</span>
                                <input type="range" min="50" max="100" className="w-full mt-2" />
                                <div className="flex justify-between text-sm mt-1">
                                    <span>$50k</span>
                                    <span>$120k</span>
                                </div>
                            </div>

                            {/* Experience Level Filter */}
                            <div className="bg-gray-100 dark:bg-slate-900 rounded-lg p-4 ">
                                <span className="font-semibold">Experience Level</span>
                                {[{ name: 'Entry Level', count: 392 }, { name: 'Intermediate', count: 124 }, { name: 'Expert', count: 3921 }].map((level) => (
                                    <div key={level.name} className="flex justify-between items-center mb-1 text-sm">
                                        <div className="flex items-center">
                                            <input type="checkbox" className="mr-2" />
                                            <span>{level.name}</span>
                                        </div>
                                        <span className="text-gray-500 dark:text-gray-400">{level.count}</span>
                                    </div>
                                ))}
                            </div>


                            {/* Job Type Filter */}


                            {/* Location Filter */}
                            <div className="bg-gray-100 dark:bg-slate-900 rounded-lg p-4 ">
                                <span className="font-semibold">Location</span>
                                {[{ name: 'Remote', count: 300 }, { name: 'New York', count: 200 }, { name: 'San Francisco', count: 150 }, { name: 'London', count: 100 }].map((location) => (
                                    <div key={location.name} className="flex justify-between items-center mb-1 text-sm">
                                        <div className="flex items-center">
                                            <input type="checkbox" className="mr-2" />
                                            <span>{location.name}</span>
                                        </div>
                                        <span className="text-gray-500 dark:text-gray-400">{location.count}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Job Listings */}
                        <div className="w-full md:w-3/4 mx-auto px-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                {jobs.map((job, index) => (
                                    <div
                                        key={index}
                                        className="bg-gray-100 dark:bg-slate-900 rounded-lg p-5 transform transition-transform hover:scale-105"
                                    >
                                        <div className="flex justify-between items-start mb-3">
                                            <div className="flex items-center gap-4">
                                                <div className="w-10 h-10 sm:w-12 sm:h-12 bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
                                                    <img
                                                        src={job.logo}
                                                        alt={`${job.company} logo`}
                                                        className="object-cover w-full h-full"
                                                    />
                                                </div>
                                                <div>
                                                    <h6 className="text-sm sm:text-lg font-semibold">{job.position}</h6>
                                                    <div className="flex text-gray-500 dark:text-gray-400 text-xs sm:text-sm">
                                                        <span>{job.company}</span>
                                                        <span className="mx-1 sm:mx-2">•</span>
                                                        <span>{job.applicants}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="text-gray-500 dark:text-purple-600 text-xl">

                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                                                </svg>

                                            </button>
                                        </div>

                                        {/* Tags section */}
                                        <div className="flex flex-wrap gap-2 mb-3">
                                            {job.tags.map((tag, i) => {
                                                let tagBgColor;
                                                switch (i % 4) {
                                                    case 0:
                                                        tagBgColor = 'bg-purple-200 text-purple-800';
                                                        break;
                                                    case 1:
                                                        tagBgColor = 'bg-orange-200 text-orange-800';
                                                        break;
                                                    case 2:
                                                        tagBgColor = 'bg-red-200 text-red-800';
                                                        break;
                                                    case 3:
                                                        tagBgColor = 'bg-green-200 text-green-800';
                                                        break;
                                                    default:
                                                        tagBgColor = 'bg-gray-200 text-gray-800';
                                                }
                                                return (
                                                    <span
                                                        key={i}
                                                        className={`${tagBgColor} rounded-lg px-2 py-1 text-xs`}
                                                    >
                                                        {tag}
                                                    </span>
                                                );
                                            })}
                                        </div>

                                        <p className="text-gray-500 dark:text-gray-400 text-sm text-left">
                                            {job.description}
                                        </p>
                                        <hr className="mt-4 border-gray-200 dark:border-gray-600" />

                                        {/* New section for Posted On and Price per Hour */}
                                        <div className="mt-4 flex justify-between items-center text-xs sm:text-sm">
                                            <span className="font-semibold">{job.salary}</span>
                                            <div className="flex items-center text-gray-500">
                                                <Clock className="w-4 h-4 mr-1" />
                                                {job.postedTime}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </>

    );
};

export default JobDashboard;
