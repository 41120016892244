import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'; // Ensure the path is correct
import Dheader from './Dheader';
import DashboardContent from '../pages/DashboardContent';

const Dashboard = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === 'true') {
            setIsAuthenticated(true);
        } else {
            navigate('/login'); // Redirect to login if no valid token
        }
    }, [navigate]);

    if (!isAuthenticated) {
        return null; // Prevent rendering the dashboard until authenticated
    }

    return (
        <div className="dashboard-container flex">
            <Sidebar /> {/* Render Sidebar here */}
            <div className="flex-1 p-6">
                {/* Header starts after the sidebar */}
                <div className="md:ml-64"> {/* Adjust the margin based on the sidebar's width */}
                    <Dheader />
                </div>
                <div className="md:ml-64">
                    <DashboardContent />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
